<template>
  <div class="SEOsetting">
    <!-- SEO设置 -->
    <div class="editContent">
      <!-- <p>基础信息</p> -->
      <div class="basicInfo">

        <el-form :model="basicRuleForm" :rules="basicRules" ref="basicRuleForm" label-width="200px" class="demo-ruleForm">
          <el-form-item label="标题">
            <el-input v-model="ruleForm.name" size="small"></el-input>
            <span>此项设置，可能会覆盖掉网站首页标题和副标题的设置（是否覆盖决定于模板）。</span>
          </el-form-item>
          <el-form-item label="关键字">
            <el-input v-model="ruleForm.name" size="small"></el-input>
          </el-form-item>
          <el-form-item label="描述">
            <el-input v-model="ruleForm.name" size="small"></el-input>
          </el-form-item>
          <!-- <el-form-item label="启用API接口">
            <el-switch v-model="ruleForm.name" >
            </el-switch>
          </el-form-item>
          <el-form-item label="应用ID">
            <el-input v-model="ruleForm.name" size="small"></el-input>
          </el-form-item>
          <el-form-item label="签名密钥" >
            <el-input v-model="ruleForm.name" size="small"></el-input>
          </el-form-item> -->

        </el-form>
      </div>
      <p>百度实时推送</p>
      <div class="updatePassword">

        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">

          <el-form-item label="启用百度实时推送">
            <el-switch v-model="ruleForm.name">
            </el-switch>
            <br />
            <span class="label">在启用该功能之前，请先去 https://ziyuan.baidu.com 申请链接提交功能 。</span>
          </el-form-item>
          <el-form-item label="推送站点">
            <el-input v-model="ruleForm.name" size="small"></el-input>
            <span class="label">例如：www.jpress.cn ，不要前缀 http:// ，请务必保证和百度申请的填写一致。</span>
          </el-form-item>
          <el-form-item label="Token">
            <el-input v-model="ruleForm.name" size="small"></el-input>
            <span class="label">请到 <a
                href="https://ziyuan.baidu.com/linksubmit/index">https://ziyuan.baidu.com/linksubmit/index</a> 获取 token
              。</span>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')" size="small">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
      <p>Ping 与 Sitemap</p>
      <div class="updatePassword">

        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">

          <el-form-item label="启用 百度 Ping">
            <el-switch v-model="ruleForm.name">
            </el-switch>
          </el-form-item>
          <el-form-item label="启用 Google Ping">
            <el-switch v-model="ruleForm.name">
            </el-switch>
          </el-form-item>
          <el-form-item label="开启 Sitmap 功能">
            <el-switch v-model="ruleForm.name">
            </el-switch>
            <br />
            <span class="label">sitemap 的默认地址是 sitemap.xml，开启后才能访问。</span>
          </el-form-item>
        </el-form>
      </div>
      <p>伪静态</p>
      <div class="updatePassword">

        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">

          <el-form-item label="启用扁平URL">
            <el-switch v-model="ruleForm.name">
              
            </el-switch>
            <br />
            <span class="label">扁平化 URL 会让目录符号 "/" 修改为 "-"，例如 www.jpress.cn/article/1.html 会变成 www.jpress.cn/article-1.html</span>
          </el-form-item>
          <el-form-item label="启用伪静态">
            <el-switch v-model="ruleForm.name">
            </el-switch>
          </el-form-item>
          <el-form-item label="伪静态后缀">
            <el-input v-model="ruleForm.name" size="small"></el-input>
            <span class="label">以英文的点（.）开头，例如：.html，当不填写时，默认值为: .html</span>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')" size="small">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var checkOld = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('旧密码不能为空！'));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'));
        } else {
          if (value < 18) {
            callback(new Error('必须年满18岁'));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    return {
      ruleForm: {
        pass: '',
        checkPass: '',
        oldPass: ''
      },
      rules: {
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        oldPass: [
          { validator: checkOld, trigger: 'blur' }
        ]
      },
      basicRuleForm: {
        name: '',
        region: '',
      },
      basicRules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        region: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],
      }

    };
  },
  methods: {
    submitBasicForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.SEOsetting {
  width: 100%;
  box-sizing: border-box;

  .editContent {
    border-radius: 5px;
    width: 70%;
    margin: 0 auto;
    // border: 1px solid #eee;
    box-shadow: 0px 0px 4px 1px #ccc;
    padding: 20px;

    p {
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
    }

    .label {
      font-size: 12px;
    }

    .basicInfo,
    .updatePassword {
      margin: auto;
      width: 60%;
      // border-bottom: 1px solid #ccc;
    }
  }
}</style>